.p-container {
  width: 100%;
  padding: 30px;

  @include mobile {
    padding: 30px 15px;
  }
}

.p-title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.p-card {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 24px rgba(55, 43, 113, 0.06);
}

.p-card-container {
  width: 100%;
  padding: 20px 30px;

  @include mobile {
    padding: 20px;
  }
}

.p-input {
  font-family: SF UI Text, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
    Arial, sans-serif;
  width: 100%;
  color: #34333d;
  line-height: 24px;
  padding: 4px 6px;
  height: 32px;
  border: none;
  border-radius: 3px;
  font-size: 13px;
  box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.2),
    inset 0 1px 2px rgba(67, 90, 111, 0.14);
  transition: all 0.25s;
  background-image: linear-gradient(#fafafa, #f8f7fa);

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px rgba(112, 100, 180, 0.7),
      inset 0 1px 2px rgba(112, 100, 180, 0.3), 0 0 0 2px rgba(112, 100, 180, 0.3);
  }
}

.p-button-link {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  &:hover {
    color: #8031d0;
  }
}

.p-table-wrapper {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 24px rgba(55, 43, 113, 0.06);
  overflow: auto;
  padding-bottom: 40px;
}

.p-table-header {
  background-color: white;
  padding: 20px 30px 15px;
  border-bottom: 1px solid #34333d1a;
  min-width: $md;

  > div {
    color: #93919c;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
  }

  > div:last-of-type {
    margin-left: auto;
  }

  > div {
    min-width: 0;
  }
}

.p-table-row {
  padding: 15px 30px;
  background-color: #f7f7fa;
  border-bottom: 1px solid #34333d1a;
  min-width: $md;

  &:nth-of-type(odd) {
    background-color: white;
  }

  > div:last-of-type {
    margin-left: auto;
  }

  > div {
    min-width: 0;

    > * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.p-table-name-col {
  font-weight: 600;
  color: #34333d;
  display: flex;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    flex-shrink: 0;

    @include laptop {
      display: none;
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.p-show-more {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #93919c;
  margin: 0;
  margin-bottom: 8px;
  text-transform: uppercase;
  margin-top: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  svg {
    width: 18px;
    height: 18px;
  }

  &.p-show-more-active {
    svg {
      transform: rotate(180deg);
    }
  }
}

.p-badge {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px 12px;
  line-height: 15px;
  color: white;
  border-radius: 10px;
  background-color: #aa4bce;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  flex-grow: 0;
  width: fit-content;

  &.p-badge-red {
    background-color: #da463c;
  }

  &.p-badge-orange {
    background-color: #ff9800;
  }

  &.p-badge-green {
    background-color: #39b96c;
  }

  &.p-badge-gray {
    background-color: #bbb9c0;
  }

  &.p-badge-teal {
    background-color: #009688;
  }

  &.p-badge-purple {
    background-color: #aa4bce;
  }

  svg {
    margin-right: 5px;
  }
}

.p-spinner-button {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin: 0;
    margin-right: 10px;
    display: block !important;
  }
}

.p-link-btn {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #8031d0;
  }

  &:disabled {
    cursor: auto;
    color: #cccccc;
  }
}

.p-draggable-item {
  cursor: move;

  > svg {
    opacity: 0.3;
  }

  * {
    pointer-events: none;
  }

  button {
    pointer-events: all;
    cursor: pointer;
  }
}

.p-error-message {
  color: #da463c;
}

.p-info-tooltip {
  display: flex;
  align-items: center;
  opacity: 0.4;
  transition: opacity 0.25s;
  margin-left: 5px;

  &:hover {
    opacity: 0.8;
  }
}

.p-h3 {
  display: flex;
  align-items: center;
  font-family: futura-pt, sans-serif;
  font-size: 22px;
  line-height: 28px;

  svg {
    margin-right: 10px;
  }
}
