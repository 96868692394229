.p-flex {
  display: flex;
}

.p-flex-column {
  display: flex;
  flex-direction: column;
}

.p-justify-center {
  justify-content: center;
}

.p-align-center {
  align-items: center;
}

.p-space-between {
  justify-content: space-between;
}

.p-align-end {
  align-items: flex-end;
}
