@import 'styles/global/mixins';

.p-report-table {
  table-layout: fixed;

  @include mobile {
    table-layout: auto;
  }

  th:first-of-type {
    width: 50px;

    @include mobile {
      width: auto;
    }
  }
}
