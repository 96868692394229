/*Base Styles*/

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  font: {
    family: $font-family-base;
    size: $font-size-base;
    weight: 400;
  }
  color: $body-color;
  background-color: $body-bg;
  height: 100%;
}

.page-heading {
  margin-bottom: $jr-card-margin + 4px;

  & .title {
    text-transform: capitalize;
    font-weight: 600;
  }

  & .breadcrumb {
    padding: 0;
    background-color: transparent;
    margin-bottom: 0;
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 18px;
  }
}

//Bootstrap Components Override

//Buttons

.pointer {
  cursor: pointer;
}

.text-decoration {
  text-decoration: none !important;
}

.font-size-16 {
  font-size: 16px !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

.step-enter {
  opacity: 0;
  transform: scale(0.9);
}
.step-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.step-exit {
  opacity: 1;
}
.step-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.appear-step-enter {
  opacity: 0;
  transform: scale(0.9);
}
.appear-step-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.appear-step-exit {
  opacity: 1;
}
.appear-step-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.stn-wdgt {
  > div {
    z-index: 100;
  }
}
