@import '_np-variables.scss';
@import '../global/variables';
@import '../global/mixins';

body {
  background: $np-bg-light;
}

.btn,
a.btn {
  transition: none;
}

.btn.btn-rounded {
  border-radius: 24px;
}

a:focus {
  outline: none;
}

.btn-icon,
.btn-icon-before,
.btn-icon-after {
  svg {
    transition: opacity 0.25s;
  }

  &.btn-primary svg {
    opacity: 1;
  }
}

.btn-icon.btn-small.btn-dropdown {
  padding: 4px;

  svg {
    width: 16px;
    height: 16px;
  }
}

[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

label.label-lg {
  font-size: 15px;
}

.form-radio input + span {
  position: relative;
}

.form-radio input + span:before {
  content: '';
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
  top: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px;
}

label.form-checkbox input:checked + span,
label.form-radio input:checked + span {
  background-color: $np-primary;
  border-color: $np-primary;
}

label.form-radio:hover,
label.form-checkbox:hover {
  input + span {
    border-color: rgba($np-text-medium, 0.3);

    &:hover {
      border-color: rgba($np-primary, 0.7);
    }
  }
}

label.form-radio input:disabled + span,
label.form-checkbox input:disabled + span {
  background-color: $np-bg-light;
  border-color: rgba($np-text-medium, 0.1);

  &:hover {
    border-color: rgba($np-text-medium, 0.1);
  }
}

label.form-radio input:not(:checked):disabled + span,
label.form-checkbox input:not(:checked):disabled + span {
  background-image: none !important;
}

label.form-checkbox:hover input:checked + span,
label.form-radio:hover input:checked + span {
  background-color: $np-primary;
}

label.form-checkbox input:active + span,
label.form-radio input:active + span,
label.form-checkbox input:focus + span,
label.form-radio input:focus + span {
  box-shadow: none;
}

.form-radio input:checked:disabled + span,
.form-checkbox input:checked:disabled + span {
  background-color: $np-primary;
  opacity: 0.5;

  &:hover {
    background-color: $np-primary;
  }
}

.help-text {
  color: $np-text-muted;
}

.nowrap {
  white-space: nowrap;
}

.form-field.fullwidth {
  width: 100%;
  max-width: 100% !important;
}

.form-field.width-160 {
  width: 100%;
  max-width: 160px !important;
}

.form-field.width-200 {
  width: 100%;
  max-width: 200px !important;
}

.form-field .field-value-display {
  margin-bottom: 20px;
  font-weight: 500;
  line-height: 24px;
  padding: 4px 6px;
  border-radius: 3px;
  border: 1px solid #d9d7e6;
}

.input-small {
  input[type='email'],
  input[type='password'],
  input[type='text'],
  input[type='number'],
  select {
    height: 28px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  &.select:after {
    height: 28px;
  }
}

.new-portal {
  .row {
    margin-left: -15px;
    margin-right: -15px;
  }

  a {
    color: $np-text-dark;

    &:not(.btn):hover {
      color: $np-secondary-darker;
    }
  }

  a.link {
    font-weight: 500;
    display: inline-block;
    text-decoration: underline;
    opacity: 0.8;
    transition: opacity 0.25s;

    &:hover {
      color: $np-text-dark;
      opacity: 1;
    }
  }

  .btn.btn-lg {
    font-size: 15px;
    line-height: 34px;
    height: 48px;
  }

  a.btn {
    color: $np-text-medium;
  }

  a.btn.btn-primary {
    color: #fff;
  }

  .btn svg.btn-loading-icon {
    display: none;
  }

  .btn.btn-loading {
    span {
      display: none;
    }

    svg.btn-loading-icon {
      display: block;
      margin: 0 auto;
      width: 18px;
      height: 18px;
      top: 2px;
    }
  }

  .btn.btn-lg.btn-loading svg.btn-loading-icon {
    width: 28px;
    height: 28px;
    top: 6px;
  }

  .left-divider {
    border-left: 1px solid rgba($np-main-dark, 0.15);
    margin-left: 20px;
    padding-left: 20px;
  }

  .pagination {
    .page-item {
      .page-link {
        border: none;
        opacity: 0.6;
        border-radius: 3px;
        font-size: 14px;
        margin-left: 2px;
        margin-right: 2px;

        &:hover {
          transition: opacity 0.25s, background-color 0.25s;
          opacity: 1;
          background-color: rgba($np-secondary, 0.1);
        }
      }

      &.active .page-link {
        font-weight: 600;
        opacity: 1;
        background-color: transparent;
        color: $np-text-dark;
        cursor: default;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .np-content {
    padding: 30px 0;
  }

  a.np-link {
    color: $np-secondary;
    transition: color 0.25s, opacity 0.25s;

    &:hover {
      color: darken($np-secondary, 8%);
      text-decoration: none;
    }
  }

  .btn.btn-primary {
    background-color: $np-primary;
    background-image: linear-gradient($np-primary, darken($np-primary, 10%));

    &:hover {
      background-image: linear-gradient(
        darken($np-primary, 6%),
        darken($np-primary, 16%)
      );
    }
  }

  .btn.btn-primary.btn-disabled.btn-loading,
  .btn.btn-primary.btn-disabled.btn-loading:hover,
  .btn.btn-primary.btn-disabled:not(.btn-loading):active,
  .btn.btn-primary.btn-disabled:not(.btn-loading):focus {
    background-color: $np-primary;
    background-image: linear-gradient($np-primary, darken($np-primary, 15%));
  }

  .btn.btn-primary.btn-disabled.btn-loading,
  .btn.btn-primary.btn-disabled.btn-loading:hover,
  .btn.btn-primary.btn-disabled:not(.btn-loading):active,
  .btn.btn-primary.btn-disabled:not(.btn-loading):focus {
    background-color: $np-primary;
    background-image: linear-gradient($np-primary, darken($np-primary, 15%));
    opacity: 0.8;

    .btn-loading-icon {
      fill: #fff;
    }
  }

  .btn.btn-primary.btn-disabled:not(.btn-loading),
  .btn.btn-primary.btn-disabled:not(.btn-loading):hover,
  .btn.btn-primary.btn-disabled:not(.btn-loading):active,
  .btn.btn-primary.btn-disabled:not(.btn-loading):focus {
    opacity: 0.5;
    cursor: default;
    background-image: none;
    background-color: #f5f5fc !important;
    color: #605d70;
    box-shadow: inset 0 0 0 1px rgba(79, 67, 111, 0.14),
      inset 0 -1px 1px 0 rgba(79, 67, 111, 0.06);
  }

  .btn.btn-danger {
    color: #fff;
    background-color: $np-danger;
    background-image: linear-gradient(lighten($np-danger, 5%), darken($np-danger, 10%));
    box-shadow: inset 0 0 0 1px rgba(darken($np-danger, 20%), 0.09),
      inset 0 -1px 1px 0 rgba(darken($np-danger, 20%), 0.1);

    &:hover {
      background-image: linear-gradient($np-danger, darken($np-danger, 15%));
    }
  }

  .btn.btn-danger-subtle:hover {
    color: #fff;
    transition: background-color 0.25s;
    background-color: $np-danger;
    background-image: none;
    box-shadow: inset 0 0 0 1px rgba(darken($np-danger, 20%), 0.09),
      inset 0 -1px 1px 0 rgba(darken($np-danger, 20%), 0.1);
  }

  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 580px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .panel {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: $box-shadow-lg;
  }

  // FORM ELEMENTS

  label .text-muted {
    font-weight: 400;
  }

  input,
  input[type='email'],
  input[type='password'],
  input[type='text'],
  input[type='number'],
  select,
  textarea {
  }

  input,
  input[type='email'],
  input[type='password'],
  input[type='text'],
  input[type='number'],
  select,
  textarea {
    &:disabled {
      background-color: $np-bg-light;
      opacity: 0.5;
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
    }
  }

  label.form-checkbox input:not(:checked) + span,
  label.form-radio input:not(:checked) + span {
    background-image: linear-gradient(#fff, #fff);
  }

  textarea {
    line-height: 1.5em;
  }

  input::placeholder {
    color: $np-text-medium;
    opacity: 0.6;
    font-size: 13px;
  }

  svg.icon-append {
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: 0.5;

    & + input {
      padding-right: 32px;
    }
  }

  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
    box-shadow: rgba(112, 100, 180, 0.7) 0px 0px 0px 1px inset,
      rgba(112, 100, 180, 0.3) 0px 1px 2px inset, 0 0 0 2px rgba(112, 100, 180, 0.3);
  }

  label.toggle-switch {
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;

    > * {
      margin-right: 10px;
    }

    input {
      display: none;
    }

    i {
      display: inline-block;
      width: 40px;
      height: 20px;
      background-color: darken($np-bg-light, 10%);
      border-radius: 12px;
      position: relative;
      top: 3px;
      transition: background-color 0.25s, box-shadow 0.25s;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 3px;
        top: 2px;
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
        background-size: 16px;
        background-position: top 0 left 0;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 2px;
        top: 2px;
        background-color: #fff;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        transition: left 0.25s;
      }
    }

    input:checked + i {
      &:after {
        left: 22px;
      }
    }

    input:checked:not(:disabled) + i {
      background-color: $np-primary;
    }

    &:hover {
      i {
        background-color: darken($np-bg-light, 15%);
      }

      input:disabled + i {
        background-color: darken($np-bg-light, 10%);
      }

      input:checked + i {
        background-color: darken($np-primary, 8%);
      }

      input:checked:disabled + i {
        background-color: darken($np-bg-light, 10%);
      }
    }

    .toggle-switch-label {
      font-weight: 500;
    }
  }

  .form-field .checkbox-group,
  .form-field .radio-group {
    margin: 10px 0;
  }

  .form-field .error-message {
    display: none;
  }

  .form-field.state-error {
    input[type='email'],
    input[type='password'],
    input[type='text'],
    input[type='number'],
    select,
    textarea {
      background-image: none;
      box-shadow: inset 0 0 0 1px $np-danger-border;
      // color: darken($np-danger, 5%);
    }

    .error-message {
      padding-top: 5px;
      color: darken($np-danger, 5%);
      display: block;
    }
  }

  .form-section {
    background-color: $np-bg-light;
    border: 1px solid $np-border-light;
    padding: 10px;
    border-radius: 4px;
  }

  .required {
    &:before {
      content: '*';
      display: inline-block;
      margin-right: 5px;
      position: relative;
      top: 4px;
      font-weight: 700;
      font-size: 18px;
      color: $np-secondary;
    }
  }

  // ALERT

  .alert-area {
    position: fixed;
    top: 66px;
    right: 20px;
    width: 100%;
    max-width: 420px;
    z-index: 9999;

    @include mobile {
      max-width: 320px;
    }
  }

  .alert {
    margin-top: 10px;
    display: flex;
    padding: 15px 10px 15px 20px;
    border-radius: 6px;
    width: 100%;
    max-width: 100%;
    box-shadow: $box-shadow-alert;
    background-color: #fff;
    border: 1px solid $np-border-light;
    color: $np-text-dark;

    &.alert-success,
    &.alert-info,
    &.alert-warning,
    &.alert-danger {
      background-color: #fff;
      border: 1px solid $np-border-light;
      color: $np-text-dark;
    }

    .alert-actions {
      display: flex;
      align-items: flex-start;
    }

    .btn-alert-close {
      border: none;
      background-color: transparent;
      padding: 0;
      opacity: 0.4;

      svg {
        width: 20px;
        height: 20px;
      }

      &:hover {
        opacity: 0.8;
        transition: opacity 0.25s;
        cursor: pointer;
      }
    }

    .alert-icon {
      align-items: center;
      display: flex;

      span {
        background-color: $np-primary;
        display: block;
        margin-right: 20px;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        padding: 6px;
      }
    }

    .alert-actions {
      margin-left: auto;
      padding-left: 20px;
    }

    h5 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    &.alert-success .alert-icon span {
      background-color: $np-success;
    }

    &.alert-info .alert-icon span {
      background-color: $np-primary;
    }

    &.alert-warning .alert-icon span {
      background-color: $np-warning;
    }

    &.alert-danger .alert-icon span {
      background-color: $np-danger;
    }

    &.alert-warning.alert-warning-bg {
      background-color: #fffaee;
      box-shadow: none;
      border: 1px solid #f5ead1;
    }
  }

  .alert-confirmation-area {
    position: fixed;
    z-index: 9999;
    background-color: rgba($np-bg-light, 0.8);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }

  .alert.alert-confirmation {
    padding: 30px;
    flex-direction: column;
    max-width: 400px;
    align-items: stretch;

    .alert-icon {
      justify-content: center;

      span {
        width: 48px;
        height: 48px;
        padding: 12px;
        margin: 0 0 20px;
      }
    }

    .alert-message {
      text-align: center;
      font-size: 15px;

      h5 {
        font-size: 20px;
        margin-bottom: 10px;
        font-weight: 500;
      }
    }

    .alert-actions {
      justify-content: center;
      padding-left: 0;
      margin: 20px 0 0;

      .btn + .btn {
        margin-left: 15px;
      }
    }
  }

  // BREADCRUMB

  .breadcrumb {
    background-color: transparent;
    padding: 0;

    a {
      color: $np-text-dark;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }

    .breadcrumb-item.active {
      color: $np-text-dark;
      opacity: 1;
    }
  }

  .breadcrumb-item + .breadcrumb-item:before {
    font-family: $np-font-family-default;
    content: '/';
    font-size: 13px;
    line-height: inherit;
    position: relative;
    top: -1px;
  }

  // DROPDOWN

  .dropdown-item {
    color: $np-text-medium;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    min-width: 140px;
    transition: background-color 0.25s, color 0.25s;

    svg {
      margin-right: 5px;

      .fill-layer {
        fill: $np-text-medium;
      }
    }

    &:hover {
      background-color: rgba($np-secondary, 0.1);
      color: darken($np-secondary, 5%);

      svg .fill-layer {
        fill: darken($np-secondary, 5%);
      }
    }
  }

  // BADGE

  .badge {
    margin-bottom: 0;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 4px 12px;
    line-height: 15px;

    &.badge-active {
      color: #fff;
      background-color: $np-secondary;
    }

    &.badge-disabled {
      color: #fff;
      background-color: lighten($np-text-muted, 15%);
    }

    &.badge-danger {
      color: #fff;
      background-color: $np-danger;
    }

    &.badge-warning {
      color: #fff;
      background-color: $np-warning;
    }

    &.badge-table {
      padding: 1px 8px;
      position: relative;
      top: -1px;
    }
  }

  // TITLE BAR

  .title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 16px;

    .flex-group {
      display: flex;
      align-items: center;
    }

    .back-link {
      opacity: 0.7;
      margin-right: 8px;
      display: block;
      height: 24px;
      margin-bottom: 10px;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    h2 {
      margin-bottom: 10px;
      font-family: $np-font-family-heading;
      font-size: 2.4rem;
      line-height: 3rem;
    }

    h3 {
      font-family: $np-font-family-heading;
      font-size: 2.2rem;
      line-height: 2.8rem;
      margin-bottom: 10px;
    }

    .top-actions {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .form-field {
        margin: 0 8px 10px 0;
      }

      .btn {
        margin-bottom: 10px;

        & + .btn {
          margin-left: 8px;
        }
      }
    }
  }

  .panel {
    .panel-header {
      padding: 20px 30px 0 30px;

      .title-bar {
        margin-bottom: 0;
      }
    }

    .panel-body {
      padding: 30px;
    }

    .panel-header + .panel-body {
      padding-top: 10px;
    }

    .panel-footer {
      padding: 15px 30px;
      display: flex;

      .pagination {
        margin-bottom: 0;
      }
    }

    .table-responsive + .panel-footer {
      margin-top: -10px;
    }
  }

  .panel .settings-section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid $np-border-light;
    padding-top: 10px;

    .settings-info {
      padding: 0 0 10px 20px;
      flex: 0 0 420px;

      h4 {
        font-weight: 600;
        margin: 0;
        position: relative;
      }

      p {
        margin: 10px 0 0;
        color: $np-text-muted;
      }
    }

    .settings-form-field {
      flex: 1;
      padding-left: 20px;

      .phone-number {
        display: flex;

        .select {
          flex: 0 0 70px;
          max-width: 70px;
        }

        .input {
          margin-left: 10px;
          flex: 0 0 180px;
          max-width: 180px;
        }
      }
    }

    .form-field {
      width: 260px;
    }

    h4.required:before {
      content: '*';
      position: absolute;
      left: -20px;
      top: 0;
      font-weight: 700;
      font-size: 18px;
      color: $np-secondary;
    }
  }

  .settings-section + .form-buttons {
    display: flex;
    margin-left: 420px;
    padding: 20px;

    .btn + .btn {
      margin-left: 8px;
    }
  }

  .panel.purge {
    .panel-header {
      border-bottom: 1px solid $np-border-light;
      margin-bottom: 10px;
    }

    .purge-body {
      padding: 20px;
      border: 1px solid $np-border-light;
      border-radius: 4px;
    }
  }

  .panel.purge .settings-section {
    align-items: stretch;
    padding-top: 20px;
    border-bottom: 0;

    &:nth-of-type(1) {
      border-bottom: 1px solid $np-border-light;
    }

    .settings-info {
      flex: 0 0 250px;
      padding-left: 0;
    }

    .form-field {
      width: 100%;
      max-width: 380px;
    }

    .textarea {
      flex: 1;
    }

    .settings-form-field {
      margin-top: -4px;
    }

    @media (max-width: 1010px) {
      .settings-info {
        margin-bottom: 10px;
      }

      .settings-form-field .form-field {
        max-width: 100%;
      }
    }
  }

  .panel.user-info .settings-section {
    align-items: stretch;
    padding-top: 10px;
    border-bottom: 0;

    .settings-info {
      flex: 0 0 150px;
    }
  }

  .panel.entity-permissions .settings-section {
    align-items: stretch;
    padding-top: 10px;
    border-bottom: 0;

    .settings-info {
      flex: 0 0 360px;
    }

    label {
      white-space: nowrap;
    }

    .form-checkbox {
      font-size: 14px;
    }
  }

  .panel.account .settings-section + .form-buttons {
    margin-left: 250px;
  }

  .panel.account .settings-section {
    align-items: flex-start;
    border-bottom: none;

    .settings-info {
      flex: 0 0 220px;
      position: relative;
      top: 4px;
      padding-bottom: 24px;
    }
  }

  .panel.account-security .settings-section {
    border-bottom: none;
    align-items: flex-start;

    .settings-info {
      flex: 0 0 420px;
      max-width: 100%;
    }

    .form-field {
      width: 350px;
      max-width: 100%;
      margin: 20px 0 0 0;
    }
  }

  @media (max-width: 1010px) {
    .panel .panel-header,
    .panel .panel-body,
    .panel .panel-footer {
      padding: 20px;
    }

    .panel .panel-header {
      padding-bottom: 0;
    }

    .panel .panel-header + .panel .panel-body {
      padding-top: 0;
    }

    .panel .settings-section {
      .settings-info {
        flex: 1 1 100% !important;
        padding-left: 0;

        h4:before {
          position: relative;
          display: inline-block;
          margin-right: 5px;
          left: 0;
          top: 3px;
        }
      }

      .settings-form-field {
        flex: 1 1 100%;
        padding-left: 0;

        .form-field {
          width: 100%;
          max-width: 320px;
        }
      }
    }

    .settings-section + .form-buttons,
    .panel.account .settings-section + .form-buttons {
      margin-left: 0;
      padding-left: 0;
    }

    .table.panel-table.panel-table-full-width tbody td:first-child,
    .table.panel-table.panel-table-full-width thead th:first-child {
      padding-left: 20px;
    }

    .table.panel-table.panel-table-full-width tbody td:last-child,
    .table.panel-table.panel-table-full-width thead th:last-child {
      padding-right: 20px;
    }
  }

  // SPINNER

  .spinner {
    display: block;
    float: left;
    width: $spinner-size;
    height: $spinner-size;
    border-radius: 50%;
    border: $spinner-unit solid $spinner-inactive;
    animation: spinner $spinner-speed linear infinite;
    position: relative;
    flex-shrink: 0;

    &:before {
      content: '';
      position: absolute;
      top: -$spinner-unit;
      left: -$spinner-unit;
      display: block;
      width: $spinner-size;
      height: $spinner-size;
      border-radius: 50%;
      border: $spinner-unit solid transparent;
      border-top-color: $spinner-active;
      top: ($spinner-unit * -3);
      left: ($spinner-unit * -3);
      width: ($spinner-size + $spinner-unit * 4);
      height: ($spinner-size + $spinner-unit * 4);
    }
  }

  @keyframes spinner {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .has-spinner {
    position: relative;
  }

  .spinner-container {
    background: $spinner-container-bg;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: inherit;
    z-index: 9;
  }

  // EMPTY STATE

  .empty-state-sm {
    padding: 25px 30px;
    background-color: $np-bg-light;
    border-radius: 4px;
    margin: 0 20px 10px 20px;

    p {
      color: $np-text-medium;
      margin-bottom: 0;
      line-height: 1.5;
      opacity: 0.8;

      a {
        text-decoration: underline;
      }
    }

    p + p {
      margin-top: 5px;
    }

    h4 {
      margin-bottom: 20px;
    }
  }

  .empty-state {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10% 30px;
    flex-direction: column;
    font-size: 32px;
    background-color: $np-bg-light;
    margin: 15px 30px 0;

    > svg {
      opacity: 0.4;
    }

    p {
      font-size: 13px;
      margin-bottom: 16px;
      color: $np-text-muted;
    }
  }

  .np-content-main-right .empty-state {
    margin: 0;
  }
}

table.services-list {
  width: 100%;

  thead {
    th {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: $np-text-muted;
      padding: 5px 10px;

      &:first-child {
        padding-left: 24px;
      }

      &:last-child {
        padding-right: 24px;
      }
    }
  }

  tbody tr {
    td {
      .service-label {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        color: $np-text-muted;
        opacity: 0.8;
        display: block;
        margin-bottom: 5px;
        display: none;
      }

      .service-value {
        a {
          color: $np-text-dark;
          transition: color 0.25s;

          &:hover {
            color: $np-secondary;
          }
        }
      }
    }
  }

  tbody tr.spacer {
    box-shadow: none;

    td {
      padding: 6px 0;
      background-color: transparent;
    }
  }

  td.name {
    .service-value {
      font-size: 15px;
      font-weight: 500;
      text-decoration: underline;
      word-break: break-all;

      span:not(:last-of-type) {
        margin-right: 10px;
      }
    }
  }

  td.traffic {
    .service-value {
      padding: 0 20px;
    }
  }

  td.domains {
    .service-value {
      display: flex;
      align-items: center;
    }
  }

  td.origins {
    .service-value {
      display: flex;
      align-items: center;
    }
  }

  td.service-actions {
    text-align: right;
    white-space: nowrap;

    .btn + .btn {
      margin-left: 5px;
    }

    .dropdown {
      display: inline-block;
      margin-left: 5px;
    }
  }
}

@media (max-width: 1200px) {
  table.services-list,
  table.services-list tbody,
  table.services-list td {
    display: block;
  }

  table.services-list tr {
    display: flex;
    flex-wrap: wrap;
  }

  table.services-list thead {
    display: none;
  }

  table.services-list tbody tr td .service-label {
    display: block;
  }

  table.services-list tbody tr {
    background-color: #fff;
    border-radius: 4px;
    position: relative;
    padding: 10px;
    margin-bottom: 16px;

    &.spacer {
      display: none;
    }

    td.service-actions {
      position: absolute;
      right: 0;
      top: 4px;
    }
  }

  table.services-list tbody tr td {
    padding: 8px 16px !important;
    text-align: left !important;
    flex: 0 0 33%;
    background-color: transparent;

    .btn.btn-icon {
      padding: 4px;
      height: 28px;
    }
  }

  table.services-list tbody tr td.traffic .service-value {
    padding: 0;
  }
}

@media (max-width: 992px) {
  table.services-list tbody tr td {
    flex: 0 0 100%;
  }
}

.table.panel-table {
  word-break: break-word;
  @include mobile {
    word-break: normal;
  }

  thead {
    th {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: $np-text-muted;
      padding: 5px 10px;
      border: none;
      border-color: $np-border-light;

      &:first-child {
        padding-left: 24px;
        border-left: 1px solid transparent;
      }

      &:last-child {
        padding-right: 24px;
        border-right: 1px solid transparent;
      }
    }
  }

  tbody {
    td {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-color: $np-border-light;
    }

    tr:nth-child(odd) td {
      background-color: $np-bg-light;
    }

    td:first-child {
      border-left: 1px solid #dee2e6;
      padding-left: 24px;
    }

    td:last-child {
      border-right: 1px solid #dee2e6;
      padding-right: 24px;
    }

    td.icon-cell {
      padding-top: 8px;
      padding-bottom: 0;
    }

    tr:last-child td {
      border-bottom: 1px solid #dee2e6;
    }

    td.table-row-actions {
      text-align: right;
      white-space: nowrap;
      padding: 8px 20px 0 10px;

      & > a {
        display: inline-block;
        opacity: 0.4;

        &:hover {
          transition: opacity 0.25s;
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 650px) {
    thead {
      th {
        padding: 5px;

        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
        }
      }
    }

    tbody {
      td {
        padding-left: 5px;
        padding-right: 5px;
      }

      td:first-child {
        padding-left: 10px;
      }

      td:last-child {
        padding-right: 10px;
      }

      td.table-row-actions {
        padding: 4px 6px 0 4px;
      }
    }
  }
}

table {
  a {
    color: $np-text-dark;

    &:hover {
      color: $np-secondary;
    }
  }

  .table-strong-link {
    color: $np-text-dark;
    font-weight: 600;
  }

  .related-with-tooltip {
    display: flex;
    align-items: center;

    .info-tooltip {
      display: flex;
      align-items: center;
      opacity: 0.4;
      transition: opacity 0.25s;
      margin-left: 5px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.table.panel-table.panel-table-full-width {
  thead {
    th {
      padding-bottom: 10px;
    }

    th:first-child {
      border-left: none;
      padding-left: 30px;
    }

    th:last-child {
      border-right: none;
      padding-right: 30px;
    }
  }

  tbody {
    td:first-child {
      border-left: none;
      padding-left: 30px;
    }

    td:last-child {
      border-right: none;
      padding-right: 30px;
    }
  }
}

// TOOLTIP

.tooltip {
  font-family: $np-font-family-default;
  font-size: 12px;
  color: $np-border-light;
  opacity: 0.95 !important;
}

.bs-tooltip-auto[x-placement^='top'] .arrow:before,
.bs-tooltip-top .arrow:before {
  border-top-color: $np-text-medium;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow:before {
  border-bottom-color: $np-text-medium;
}

.bs-tooltip-auto[x-placement^='left'] .arrow:before {
  border-left-color: $np-text-medium;
}

.bs-tooltip-auto[x-placement^='right'] .arrow:before {
  border-right-color: $np-text-medium;
}

.tooltip-inner {
  background-color: $np-text-medium;
  max-width: 240px;

  > div {
    padding: 8px;
    text-align: left;
    font-size: 13px;
  }

  ul {
    list-style: none;
    padding: 5px;
    margin: 0;

    li + li {
      margin-top: 3px;
    }

    a {
      color: lighten($np-secondary, 38%);
      text-decoration: underline;

      &:hover {
        transition: color 0.25s;
        color: lighten($np-secondary, 34%);
      }
    }
  }

  .tooltip-barchart-label,
  .tooltip-barchart-value {
    margin: 5px;
    line-height: 1.3;
    color: lighten($np-text-muted, 25%);
  }
}

.np-content-2col {
  position: relative;
  padding-left: 200px;
  min-height: 200px;

  .np-content-nav-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 170px;

    .nav {
      flex-direction: column;
    }

    .nav-item {
      a.nav-link {
        font-size: 12px;
        text-transform: uppercase;
        color: $np-text-medium;
        font-weight: 600;
        padding: 12px 18px;
        background-color: transparent;
        transition: color 0.25s, background-color 0.25s;
        border-radius: 4px;

        &:hover {
          color: darken($np-secondary, 5%);
        }

        &.active {
          background-color: rgba($np-secondary, 0.1);
          color: darken($np-secondary, 5%);
          cursor: default;
        }
      }

      &.nav-item-last {
        margin-right: auto;
      }
    }

    .nav-item + .nav-item {
      margin-top: 6px;
    }
  }
}

@media (max-width: 768px) {
  .np-content-2col {
    padding-left: 0;

    .np-content-nav-left {
      position: static;
      width: 100%;
      margin-bottom: 10px;

      .nav {
        max-width: 420px;
        flex-direction: row;
        justify-content: flex-start;

        .nav-item + .nav-item {
          margin-top: 0;
        }

        .nav-item a.nav-link {
          padding: 8px 12px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .np-content-2col .np-content-nav-left .nav .nav-item a.nav-link {
    padding: 8px 10px;
    font-size: 11px;
    font-weight: 500;
  }
}

// MODAL

.modal-backdrop.show {
  background: $modal-backdrop-bg;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0.9;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-body hr {
  margin: 20px -30px 20px -30px;
  border-top-color: $np-border-light;
}

.modal-content {
  border-radius: 8px;
  box-shadow: $box-shadow-lg;
}

.modal-header {
  padding: 16px 30px;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid $np-border-light;
  align-items: center;

  .modal-title {
    font-family: $np-font-family-heading;
    font-size: 20px;
  }

  .close {
    opacity: 0.4;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 -10px 0 10px;

    &:hover {
      transition: opacity 0.25s;
      opacity: 1;
    }
  }
}

.modal-body {
  padding: 20px 30px;
  background-color: $np-bg-light;

  .form-field {
    width: 100%;
    max-width: 260px;
    margin-bottom: 10px;
  }
}

.modal-footer {
  border-top: 1px solid $np-border-light;
  padding: 16px 30px;

  .btn + .btn {
    margin-left: 10px;
  }
}

.upload-certificate-modal,
.file-upload {
  .modal-dialog {
    width: 100%;
    max-width: 1300px !important;
  }

  .modal-body {
    padding-top: 0;
    padding-bottom: 0;

    .row > div {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    h4 {
      margin-bottom: 20px;

      strong {
        font-weight: 600;
      }
    }
  }

  .upload-area {
    background-color: #fff;
    border: 1px dashed #d0ccd6;
    border-radius: 4px;
    padding: 30px 20px;
    text-align: center;
    color: $np-text-muted;

    a {
      color: $np-primary;
      font-weight: 500;
    }

    &:hover {
      transition: border-color 0.25s, background-color 0.25s;
      border-color: lighten($np-primary, 20%);
      background-color: lighten($np-primary, 40%);
    }
  }

  .upload-text {
    text-align: center;
    padding: 10px 20px;
    color: $np-text-muted;
  }

  .form-field.fullwidth {
    width: 100%;
    max-width: 100%;

    textarea {
      overflow-wrap: normal;
      padding: 10px;
      font-family: $np-font-family-monospace;
    }
  }

  .cert-content {
    font-family: $np-font-family-monospace;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    border: 1px solid $np-border-light;
    border-radius: 4px;
    min-width: 1px;
    background-color: #fff;

    .textarea {
      padding: 10px;
      width: 100%;
      min-width: 700px;
      background-color: #fff;
    }
  }

  .cert-info {
    margin-bottom: 10px;

    .label {
      margin-bottom: 2px;
    }
  }
}

.origin-modal {
  .modal-dialog {
    width: 100%;
    max-width: 500px !important;
  }
}

.modal-dialog.modal-small {
  max-width: 450px;

  .modal-header,
  .modal-body {
    padding: 10px 15px;
  }

  .modal-footer {
    background-color: $np-bg-light;
    border-top: none;
    border-radius: 0 0 8px 8px;
    padding: 0;
    height: 8px;
  }

  .modal-header .modal-title {
    font-family: $np-font-family-default;
    font-size: 16px;
  }
}

.modal.wizard-feedback-modal {
  .modal-dialog {
    max-width: 550px;
    text-align: center;

    .modal-header {
      display: block;
      position: relative;
      background-color: $np-bg-light;
      padding-top: 40px;
      padding-bottom: 40px;

      button.close {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }

    .modal-body {
      background-color: #fff;
      border-top: $np-bg-light;
      padding: 40px;
      padding-bottom: 30px;

      h4 {
        font-weight: 500;
      }

      .next-step-info {
        opacity: 0.6;

        a {
          text-decoration: underline;

          &:hover {
            color: #000;
          }
        }
      }
    }

    .modal-footer {
      border-top: none;
      justify-content: center;
      padding-bottom: 40px;
      padding-top: 0;
    }
  }

  .success-message {
    font-size: 16px;
    font-weight: 500;

    .success-check {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0 auto 15px;
      background-color: $np-success;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .success-info {
    margin-top: 20px;
    line-height: 1.6;

    strong {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.modal.service-wizard-modal {
  .modal-dialog {
    max-width: 550px;
  }

  .modal-footer {
    justify-content: flex-start;
  }

  ul.zebra {
    margin-left: -30px;
    margin-right: -30px;
    padding: 0;
    list-style: none;

    li {
      padding: 6px 30px;
      display: flex;
      font-weight: 500;

      .key {
        flex: 0 0 220px;
        font-weight: 400;
      }
    }

    &.even {
      li:nth-child(even) {
        background-color: #efeff5;
      }
    }

    &.odd {
      li:nth-child(odd) {
        background-color: #efeff5;
      }
    }
  }

  table.zebra {
    .btn-small {
      padding: 4px 9px;
      height: 30px;
      margin-top: -2px;
      margin-bottom: -2px;

      svg {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }
    }

    &.odd {
      tr:nth-child(odd) td {
        background-color: #efeff5;
      }
    }

    td {
      border: none;
      vertical-align: middle;
      padding-top: 7px;
      padding-bottom: 7px;
    }

    td:first-child {
      padding-left: 30px;
      font-weight: 500;
    }

    td:last-child {
      padding-right: 30px;
      text-align: right;

      a {
        display: inline-block;
        opacity: 0.5;
        transition: opacity 0.25s;

        &:hover {
          opacity: 1;
        }

        svg {
          width: 20px;
          height: 20px;
          position: relative;
          top: 2px;
        }
      }
    }

    .has-certificate {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
    }
  }

  p.disclaimer {
    font-size: 12px;
    opacity: 0.5;
  }

  .add-domain-form {
    display: flex;

    .form-item:first-child {
      flex: 1;
      margin-right: 6px;
    }

    .form-item .btn {
      position: relative;
      top: 24px;
    }
  }
}

.new-portal .panel.service-rules {
  .table-responsive {
    overflow: auto;
  }

  .panel-body {
    padding-top: 20px;
    padding-bottom: 0;

    .empty-state {
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }

  .visual {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .visual-legend {
    ul {
      list-style: none;
      display: flex;
      justify-content: flex-end;
      margin: 0;
      padding: 0;
    }

    li {
      margin-left: 20px;

      > span {
        border: 1px solid $np-border-light;
        border-radius: 4px;
        display: inline-block;
        width: 18px;
        height: 18px;
        position: relative;
        top: 4px;
        margin-right: 3px;
      }
    }

    .legend-condition {
      background-color: $condition-bg;
    }

    .legend-action {
      background-color: $action-bg;
    }
  }

  .code-editor-wrapper {
    border-radius: 4px;
    min-height: 400px;
    flex: 1;
  }
}

.new-portal .service-rules .visual {
  overflow-x: auto;
  padding: 20px;
  border: 1px solid $np-border-light;
  border-radius: 4px;
  flex: 1;

  .btn.btn-primary.btn-small.btn-rounded.btn-icon {
    width: 25px;
  }

  .rules-tree-top {
    margin-bottom: 0;
  }

  .rules-tree-top span {
    border: 1px solid $np-border-rules;
    display: inline-block;
    padding: 8px 18px;
    background-color: $np-bg-light;
    border-radius: 40px;
    margin-bottom: 0;
    position: relative;
    font-weight: 500;
    font-size: 14px;

    &:after {
      content: '';
      position: absolute;
      bottom: -25px;
      left: 24px;
      display: block;
      height: 24px;
      width: 1px;
      background-color: $np-border-rules;
    }
  }

  ul.rules-tree {
    list-style: none;
    margin: 0 0 0;
    border-left: 1px solid $np-border-rules;
    padding-left: 20px;

    ul {
      border-left: 1px solid $np-border-rules;
      margin-top: $spacer * 2;
      padding-left: $spacer * 2;
      margin-left: $spacer * 2;
    }

    li {
      display: block;
      margin: $spacer 0;
      position: relative;
      left: $spacer * 2-$spacer * 4;

      .rule-row-inner {
        display: flex;
        align-items: center;
      }

      div.item {
        border: 1px solid $np-border-rules;
        background-color: $np-bg-light;
        border-radius: 4px;
      }

      div.connector {
        width: $spacer * 2;
        flex: 0 0 $spacer * 2;
        height: 1px;
        background-color: $np-border-rules;

        &.button-connector {
          width: $spacer;
          flex: 0 0 $spacer;
        }
      }
    }

    li:last-child {
      position: relative;
      margin-bottom: 0;

      &:before {
        content: '';
        position: absolute;
        height: 50%;
        top: 51%;
        left: -1px;
        width: 1px;
        background-color: #fff;
      }
    }

    li.rule-indented-section {
      padding-left: $spacer * 2;
      position: relative;

      ul {
        margin-top: $spacer - $spacer * 2;
        padding-top: $spacer;

        li:last-child {
          margin-bottom: 0;
        }

        li:first-child {
          margin-top: 0;
        }
      }
    }

    li.rule-branch-node-row {
      .item {
        border: 1px solid #d8d8d9;
        display: inline-block;
        padding: 4px 10px;
        background-color: $branch-bg;
      }
    }

    li.rule-end-row {
      .item {
        border-radius: 30px;
        font-weight: 500;

        span {
          white-space: nowrap;
        }
      }

      .item.end-regular {
        padding: 6px 10px;
        background-color: $np-bg-light;
        border: 1px solid darken($np-border-light, 8%);
        font-size: 14px;
      }

      .item.end-warning {
        border: 1px solid $np-danger;
        display: inline-block;
        padding: 0;
        background-color: rgba($np-danger, 0.1);
        color: darken($np-danger, 5%);

        .end-icon {
          flex: 0 0 32px;
          height: 28px;
          background-color: $np-danger;
          border-radius: 30px 0 0 30px;
          padding: 4px 6px 4px 8px;

          svg {
            display: block;
          }
        }
      }

      .item.end-fallback {
        padding: 0;

        a {
          font-weight: 600;
          text-decoration: underline;
        }

        .end-icon {
          flex: 0 0 32px;
          height: 32px;
          background-color: $np-border-light;
          border-radius: 30px 0 0 30px;
          padding: 4px 6px 4px 8px;

          svg {
            display: block;
            position: relative;
            top: 2px;
          }
        }
      }
    }

    li.rule-action-row {
      .item {
        display: flex;
      }

      .action-cell {
        background-color: $action-bg;
        font-weight: 500;
        padding: 5px 5px 5px 10px;
        border-right: 1px solid $np-border-rules;
        border-radius: 4px 0 0 4px;
        display: flex;

        select {
          font-weight: 500;
          -webkit-box-shadow: inset 0 0 0 1px rgba(11, 0, 64, 0.14),
            inset 0 1px 2px rgba(72, 67, 111, 0.14);
          -moz-box-shadow: inset 0 0 0 1px rgba(11, 0, 64, 0.14),
            inset 0 1px 2px rgba(72, 67, 111, 0.14);
          box-shadow: inset 0 0 0 1px rgba(11, 0, 64, 0.14),
            inset 0 1px 2px rgba(72, 67, 111, 0.14);
        }

        span {
          white-space: nowrap;
        }
      }

      .action-details-container {
        padding: 0 10px;
        min-width: 30px;
      }

      .action-details {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        position: relative;
        padding-right: 20px;

        > div {
          margin: 5px 10px;

          strong {
            font-weight: 500;
          }
        }
      }

      &.action-terminal {
        .rule-row-inner {
          position: relative;
          min-width: 290px;

          &:before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 1px;
            border-bottom: 1px dashed $np-danger;
            left: 0;
            bottom: -15px;
          }

          &:after {
            content: 'Actions below a terminal action will not be applied';
            position: absolute;
            display: inline-block;
            background-color: #fff;
            font-size: 11px;
            font-weight: 600;
            color: $np-danger;
            padding: 2px 10px 2px 5px;
            left: 15px;
            bottom: -26px;
            white-space: nowrap;
          }
        }

        .action-terminal-marker {
          display: flex;
          align-items: flex-start;
          margin: 5px 0 0 -20px;

          .rules-info-tooltip {
            opacity: 1;
          }
        }

        & + li {
          padding-top: 20px;
        }

        & ~ li {
          &:before {
            top: 70%;
          }

          .item {
            opacity: 0.3;
          }
        }
      }
    }

    li.rule-condition-row {
      .condition-details {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        position: relative;
        padding-right: 20px;
        min-height: 30px;
        min-width: 30px;

        > div {
          margin: 5px 10px;
        }
      }
    }

    li.rule-condition-row {
      .condition-cell.no-details + td .condition-details .delete-rule-item {
        position: relative;
        display: inline-block;
        padding: 0 5px;
        top: 9px;
        right: -10px;
      }
    }

    li.rule-buttons-row {
      padding-top: calc($spacer/4);
      padding-bottom: calc($spacer/4);
    }

    .rules-info-tooltip {
      opacity: 0.4;
      position: relative;
      top: 2px;
      margin-left: 2px;

      svg {
        display: block;
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.25s;
      }
    }
  }

  .rules-tree-top + ul {
    margin-left: 25px;
  }

  table {
    td {
      padding: 0 10px;
      border-right: 1px solid $np-border-rules;

      &:last-child {
        border-right: none;
      }
    }

    tr + tr td {
      border-top: 1px solid lighten($np-border-rules, 5%);

      &.condition-cell {
        border-top-color: #d5cefd;
      }
    }

    .condition-cell {
      padding: 5px 5px 5px 10px;
      background-color: $condition-bg;
      font-weight: 500;
      vertical-align: top;

      select {
        font-weight: 500;
        -webkit-box-shadow: inset 0 0 0 1px rgba(11, 0, 64, 0.14),
          inset 0 1px 2px rgba(72, 67, 111, 0.14);
        -moz-box-shadow: inset 0 0 0 1px rgba(11, 0, 64, 0.14),
          inset 0 1px 2px rgba(72, 67, 111, 0.14);
        box-shadow: inset 0 0 0 1px rgba(11, 0, 64, 0.14),
          inset 0 1px 2px rgba(72, 67, 111, 0.14);
      }
    }

    tr:first-child .condition-cell {
      border-top-left-radius: 4px;
    }

    tr:last-child .condition-cell {
      border-bottom-left-radius: 4px;
    }

    .condition-cell.no-details {
      border-right: 0;

      & + td {
        padding: 0;
      }
    }
  }

  .item div.item-actions {
    position: absolute;
    margin-right: 3px !important;
    right: 0;
    top: 3px;

    .btn {
      background-image: none;
      background-color: transparent;
      box-shadow: none;
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }
  }

  .item .input,
  .item .select,
  .item .textarea {
    display: flex;

    label {
      margin: 4px 5px 0 0;
      font-weight: 400;
    }
  }

  label.form-checkbox {
    margin: 2px 5px 0 0;
  }

  input[type='email'],
  input[type='password'],
  input[type='text'],
  input[type='number'],
  select,
  textarea {
    width: auto;
    max-width: 100%;
  }

  .drag-handle {
    float: left;
    margin-left: -10px;
    position: relative;
    top: 4px;
    opacity: 0.4;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      opacity: 0.8;
      transition: opacity 0.25s;
      cursor: move;
    }
  }

  .delete-rule-item {
    position: absolute;
    top: 9px;
    right: 5px;
    opacity: 0.4;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      opacity: 1;
      transition: opacity 0.25s;
      cursor: pointer;
    }
  }
}

.new-portal .service-rules .visual .rules-tree li.rule-branch-node-row {
  div.item {
    position: relative;
    padding: 5px 30px 5px 15px;
    font-weight: 500;
  }

  .rules-info-tooltip {
    margin-top: -3px;
    top: 1px;
    margin-right: -3px;

    svg {
      display: block;
    }
  }
}

.new-portal .service-rules .rules-tree li.rule-indented-section:last-child:before {
  top: auto;
  bottom: 1px;
  height: calc(50% + 39px);
}

.new-portal .service-rules .visual.visual-readonly {
  // background-color: darken($np-bg-light, 1%);

  ul.rules-tree .rules-info-tooltip {
    margin-bottom: -3px;
    top: -2px;
    margin-right: -3px;
  }

  .rules-tree .rule-indented-section:last-child li.line-cover {
    position: absolute;
    height: 50%;
    left: -1px;
    width: 1px;
    bottom: 1px;
    //    background-color: blue;
  }

  li.rule-action-row .action-details,
  li.rule-condition-row .condition-details {
    padding-right: 0;
  }

  li.rule-condition-row .condition-details {
    min-height: 0;
  }

  table .condition-cell {
    padding-right: 10px;
  }

  li.rule-action-row .action-cell {
    padding-right: 10px;
  }

  ul.rules-tree li.rule-branch-node-row {
    .rules-info-tooltip {
      top: 0;
    }

    div.item {
      top: 2px;
      padding-right: 10px;
    }
  }
}

.new-portal .service-rules .rules-tree .has-error {
  .item {
    border-color: rgba($np-danger, 0.2) !important;
    background-color: rgba($np-danger, 0.1) !important;

    .select select,
    .input input {
      box-shadow: none !important;
      border: 1px solid rgba($np-danger, 0.3) !important;
    }
  }

  .action-cell,
  .condition-cell {
    background-color: rgba($np-danger, 0.15) !important;
    border-right-color: rgba($np-danger, 0.2) !important;
  }

  .error-message {
    color: $np-danger;
    background-color: rgba($np-danger, 0.08);
    padding: 5px 10px;
    min-width: 150px;
    margin-left: 10px;
    border-radius: 4px;
  }
}

.new-portal .service-rules {
  .title-bar .top-actions {
    @media (max-width: 600px) {
      display: none;
    }
  }

  .panel-header {
    border-bottom: 1px solid $np-border-light;
  }

  .panel-footer {
    border-top: 1px solid $np-border-light;
    display: flex;
    justify-content: flex-end;
    padding: 20px 30px;

    .btn + .btn {
      margin-left: 10px;
    }
  }
}

.new-portal .panel .nav.nav-pills {
  .nav-item {
    font-size: 14px;
    font-weight: 500;
    margin: 0 20px;

    &:first-child {
      margin-left: 0;
    }

    .nav-link {
      opacity: 0.6;
      border-bottom: 3px solid transparent;
      border-radius: 0;
      padding: 10px 0;

      &:hover {
        opacity: 1;
      }

      &.active {
        opacity: 1;
        background-color: transparent;
        border-bottom-color: $np-primary;
        color: $np-text-dark;
        cursor: default;
      }
    }
  }
}

.new-portal {
  .certitficate-title {
    span {
      font-family: $np-font-family-default;
      font-size: 20px;
      font-weight: 400;
    }
  }

  .certificate-details,
  .token-details,
  .user-security {
    padding: 25px 25px 15px;
    background-color: $np-bg-light;
    border: 1px solid $np-border-light;
    border-radius: 4px;

    .key-value-list {
      flex: 1;
      min-width: 370px;
      padding-left: 17px;
      padding-right: 17px;

      > li {
        margin: 15px 0;

        &:first-child {
          margin-top: 0;
        }

        .key {
          flex: 0 0 100px;
          text-align: right;
        }

        .value {
          word-wrap: break-word;
          min-width: 0;

          .badge {
            margin-right: 5px !important;
          }
        }
      }
    }
  }

  .key-value-list {
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      display: flex;

      .key {
        color: $np-text-medium;
        margin-right: 10px;
      }

      .value {
        font-weight: 500;
        word-wrap: break-word;
        min-width: 0;
      }
    }
  }

  ul.link-list-small {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      line-height: 1.3em;
    }

    li + li {
      margin-top: 8px;
    }

    a {
      color: $np-text-medium;
      text-decoration: underline;
      font-weight: 400;
      word-wrap: break-word;

      &:hover {
        color: $np-secondary;
      }

      &.more-link {
        opacity: 0.6;
        transition: opacity 0.25s, color 0.25s;
        &:hover {
          color: $np-secondary;
          opacity: 1;
        }
      }
    }
  }

  .token {
    .regenerate-token-alert {
      .alert-icon {
        align-items: flex-start;
      }

      @media (max-width: 600px) {
        margin-top: -10px;
        flex-direction: column;
        align-items: center;

        .alert-icon {
          align-items: center;

          span {
            margin: 0;
          }
        }

        .alert-message {
          margin-top: 10px;
          margin-bottom: 20px;
        }

        .alert-actions {
          align-items: center;
          margin-left: 0;
        }
      }
    }
  }

  .token-details {
    .key-value-list > li .key {
      flex: 0 0 80px;
    }

    & + h4 {
      font-family: $np-font-family-heading;
      font-size: 20px;
      margin-top: 30px;
      border-bottom: 1px solid $np-border-light;
      padding-bottom: 10px;
      margin-bottom: 30px;
    }

    @media (max-width: 600px) {
      padding: 20px 10px 10px;

      .key-value-list {
        min-width: 0;
      }

      .key-value-list > li {
        flex-direction: column;

        .key {
          text-align: left;
          flex: 0 0 auto;
        }

        .value {
        }
      }
    }
  }
}

.new-portal .service-overview {
  .view-more-link {
    background-color: rgba($np-secondary, 0.08);
    color: $np-secondary;
    padding: 4px 14px;
    line-height: 24px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;

    &:hover {
      text-decoration: none;
      background-color: rgba($np-secondary, 0.12);
      transition: background-color 0.25s;
    }
  }

  .panel-widget.service-info {
    .panel-body {
      padding-top: 22px;
      padding-bottom: 22px;
      padding-right: 160px;
      position: relative;

      .view-more-link {
        position: absolute;
        right: 30px;
        top: 25px;
      }

      @include tablet {
        li:first-of-type {
          width: 100%;
        }
      }

      @media (max-width: 600px) {
        padding-right: 30px;
        padding-bottom: 74px;

        .view-more-link {
          top: auto;
          right: auto;
          bottom: 30px;
          left: 20px;
        }
      }
    }
  }

  .key-value-list {
    display: flex;
    flex-wrap: wrap;

    li {
      margin: 8px 6% 8px 0;
      align-items: center;
    }

    .key,
    .value {
      flex-wrap: nowrap;
    }

    .text-lg {
      font-size: 1.2em;
    }
  }

  .title-date-range {
    font-family: $np-font-family-default;
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
  }

  .kpi-title {
    text-transform: uppercase;
    color: $np-text-medium;
    font-weight: 500;
    padding-bottom: 4px;
    border-bottom: 1px solid $np-border-light;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .kpi-content {
    display: flex;
    margin-bottom: 30px;

    .kpi-values {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      div {
        white-space: nowrap;
        font-size: 15px;
        font-weight: 500;

        svg {
          position: relative;
          top: 3px;
          width: 18px;
          height: 18px;
        }
      }

      div + div {
        margin-top: 3px;
      }

      .success {
        color: $np-success;

        .fill-layer {
          fill: $np-success;
        }
      }

      .danger {
        color: $np-danger;

        svg {
          transform: rotate(180deg);
        }

        .fill-layer {
          fill: $np-danger;
        }
      }
    }

    .kpi-chart-area {
      margin-left: 5px;
    }
  }

  @media (max-width: 600px) {
    .kpi-content {
      flex-direction: column;

      .kpi-values {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .kpi-chart-area {
        margin-left: 0;
      }
    }
  }
}

body.user-auth.new-portal {
  #root {
    display: flex;
    min-height: 100%;
    height: auto;
  }

  .user-auth-wrapper {
    display: flex;
    min-height: 100%;
    justify-content: space-between;
    flex-direction: column;
    background-color: $np-primary;
    background: linear-gradient(160.21deg, $np-primary 0%, $gradient-dark 87.92%);
    padding: 30px;
    width: 100%;

    @media (max-width: 480px) {
      padding: 10px;
    }
  }

  .user-auth-main {
    .logo {
      display: block;
      margin: 10px auto 40px;
      width: 160px;

      img {
        width: 100%;
      }

      @media (max-width: 480px) {
        margin: 10px auto 20px;
        width: 130px;
      }
    }
  }

  .panel.user-auth-card {
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 20px;

    @media (max-width: 480px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    .instructions {
      font-size: 14px;
      line-height: 1.6;

      p {
        margin: 0;
      }

      p + p {
        margin-top: 10px;
      }
    }

    a:not(.btn) {
      text-decoration: underline;
      opacity: 0.7;
      font-weight: 400;
      font-size: 13px;

      &:hover {
        opacity: 1;
        transition: opacity 0.25s;
        color: $np-secondary-darker;
      }
    }

    .user-list {
      list-style: none;
      margin: 20px 0 0;
      padding: 0;

      li + li {
        margin-top: 5px;
      }

      li {
        display: block;
        padding: 10px 15px;
        background-color: $np-bg-light;
        border: 1px solid $np-border-light;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          background-color: rgba($np-secondary, 0.1);

          transition: background-color 0.25s;
        }
      }
    }

    .panel-header {
      padding: 30px 0 5px;

      text-align: center;
      font-size: 14px;

      h2 {
        margin: 0 0 20px;
        font-size: 20px;
      }

      .alert {
        box-shadow: none;
        border: none;
        text-align: center;
        display: block;

        &.alert-danger {
          color: $np-danger;
          background-color: rgba($np-danger, 0.1);
        }
      }
    }

    .panel-body {
      background-color: $np-bg-light;
      border: 1px solid $np-border-light;
      border-radius: 6px;
      padding-top: 30px;
      padding-bottom: 25px;
      flex: 1;

      @media (max-width: 480px) {
        padding-top: 20px;
        padding-bottom: 15px;
      }

      &.panel-body-transparent {
        background-color: transparent;
        border: none;
        padding-left: 0;
        padding-right: 0;
      }

      hr {
        margin-top: 0;
        margin-bottom: 0;
        border-top-color: $np-border-light;
      }

      .form-field {
        width: 100%;

        label {
          font-size: 14px;
        }

        .input input,
        .select select,
        .textarea textarea {
          font-size: 15px;
          line-height: 24px;
          height: 36px;
          padding-left: 10px;
        }
      }

      .btn {
        width: 100%;
        font-size: 15px;
        line-height: 34px;
        height: 48px;
        margin-top: 8px;

        @media (max-width: 480px) {
          font-size: 13px;
          line-height: 24px;
          height: 36px;
          margin-top: 0;
        }
      }
    }

    .panel-footer {
      padding: 20px 0 30px;
      text-align: center;
      display: block;
    }

    .gen-pass-btn {
      text-decoration: underline;
      opacity: 0.7;
      font-weight: 400;
      font-size: 13px;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;

      &:hover {
        opacity: 1;
        transition: opacity 0.25s;
        color: #8031d0;
      }
    }
  }

  .user-auth-footer {
    display: flex;
    justify-content: center;

    ul {
      margin: 30px 0 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      li {
        margin-bottom: 10px;
        padding: 0 15px;

        a {
          color: $np-bg-light;
          opacity: 0.7;
        }
      }
    }
  }

  .panel.panel-secondary {
    background-color: rgba(255, 255, 255, 0.15);
    color: $np-bg-light;
    margin: 0 auto 30px;

    a {
      color: #fff;
      opacity: 0.8;
      text-decoration: underline;

      &:hover {
        transition: opacity 0.25s;
        opacity: 1;
      }
    }

    h6 {
      font-size: 14px;
      font-weight: 500;
    }

    ul {
      padding-left: 15px;
      margin-bottom: 0;

      li {
        margin: 5px 0;

        a {
          text-decoration: none;
        }
      }
    }
  }

  .panel.user-auth-card + .panel.panel-secondary {
    max-width: 470px;

    .panel-body {
      padding: 20px 40px;
    }
  }

  .panel.user-auth-card.twofa-setup-card {
    max-width: 520px;

    & + .panel.panel-secondary {
      max-width: 520px;
    }
  }
}

table.table.panel-table.user-permissions-table {
  text-align: center;
  margin-top: 20px;

  th {
    color: $np-text-dark;
    padding-left: 15px;
    padding-right: 15px;
  }

  thead th {
    padding-left: 0;
    padding-right: 0;

    span {
      padding: 0 5px;
    }
  }

  td {
    background-color: #fff !important;
    border-left: 1px solid $np-border-light;
    padding-left: 15px;
    padding-right: 15px;

    &:first-child {
      font-weight: 500;
      text-align: right;
    }

    label {
      margin-bottom: 0;
      justify-content: center;
      width: 100%;

      span {
        margin: 0;
      }
    }
  }
}

.services-check-list {
  padding: 10px 15px;
  margin-bottom: 10px;
  border: 1px solid $np-border-light;
  background-color: #fff;
  border-radius: 3px;
  max-height: 290px;
  overflow-y: auto;
  container-type: inline-size;
  container-name: services-check-list;

  label.form-checkbox {
    white-space: nowrap;
  }

  ul {
    margin: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
  }

  hr {
    margin: 10px 0;
  }
}

@container services-check-list (max-width: 768px) {
  .services-check-list ul {
    grid-template-columns: 1fr 1fr;
  }
}

@container services-check-list (max-width: 450px) {
  .services-check-list ul {
    grid-template-columns: 1fr;
  }
}

.form-radio.readonly {
  opacity: 0.6;
  font-size: 14px;
  margin-top: 4px;
}

.form-radio.readonly.selected,
.form-checkbox.readonly.selected {
  opacity: 1;
  padding-right: 24px;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%237064b4'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: right -3px;
  font-weight: 600;
}

table .form-radio.readonly.selected {
  margin-top: 0;
  height: 24px;
  background-size: 24px;
  background-position: center;
}

.modal.add-user-modal .modal-dialog {
  max-width: 350px;
}

a.forgot-password-link {
  text-decoration: underline;
  opacity: 0.7;
  font-weight: 400;
  font-size: 13px;
  transition: opacity 0.25s;

  &:hover {
    opacity: 1;
  }
}

.new-portal .warning {
  padding: 10px;
  border-radius: 4px;
}

.new-portal .modal .alert {
  &.alert-warning {
    background-color: lighten($np-warning, 45%);
    box-shadow: none;
  }
}

.new-portal .alert.alert-vertical {
  flex-direction: column;
  align-items: center;
}

.new-portal .alert.alert-transparent {
  padding: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.new-portal {
  .panel-section {
    background-color: $np-bg-light;
    border: 1px solid $np-border-light;
    padding: 20px;
    border-radius: 5px;
  }

  .panel-body .panel-section {
    margin-bottom: 10px;
  }

  .list-with-actions {
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid $np-border-light;
    margin-bottom: 10px;

    li {
      font-size: 14px;
      padding: 6px 0;
      border-top: 1px solid $np-border-light;
      display: flex;
      align-items: center;

      .available-actions {
        margin-left: auto;
        display: flex;
        align-items: center;
      }
    }
  }

  .user-auth-card .alert.alert-warning {
    background-color: #fff2d5;
    box-shadow: none;

    @media (max-width: 480px) {
      flex-direction: column;

      .alert-icon {
        align-self: center;
        margin-bottom: 10px;
      }
    }
  }

  .user-auth-card h3,
  .user-auth-card h4 {
    font-weight: 500;
  }

  .twofa-barcode {
    width: 240px;
    height: 240px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid $np-border-light;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .configuration-step {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    margin: -10px;

    span {
      flex: 0 0 28px;
      width: 28px;
      height: 28px;
      line-height: 26px;
      font-weight: 600;
      display: block;
      text-align: center;
      background-color: #fff;
      border: 1px solid $np-border-light;
      border-radius: 50%;
      margin-right: 16px;
      position: relative;
      top: -5px;
    }

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;

    text-align: center;

    h1 {
      font-size: 100px;
      color: #fff;
      font-weight: 600;
      // text-shadow: 1px 1px 40px rgba($np-secondary, .2),
      //   1px 1px 180px rgba($np-secondary, .1),
      //   0 0 1px rgba($np-secondary, .7);

      // color: rgba(0, 0, 0, 0.6);
      // text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2),
      //   0px -5px 35px rgba(255, 255, 255, 0.3);
      // margin-bottom: 20px;

      background-color: #8a82b8;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      background-clip: text;
      color: transparent;
      text-shadow: rgba(255, 255, 255, 0.3) 0px 3px 3px;
    }

    .error-page-caption {
      font-size: 30px;
      margin-bottom: 4px;
      opacity: 0.9;
    }

    .error-page-description {
      font-size: 16px;
      opacity: 0.7;
      margin-bottom: 30px;
    }

    .error-page-links {
      display: flex;

      a {
        font-weight: 600;
        font-size: 14px;
        text-decoration: underline;
      }
    }

    @media (max-width: 480px) {
      padding: 30px 10px;

      h1 {
        font-size: 72px;
      }

      .error-page-caption {
        font-size: 24px;
      }

      .error-page-description {
        font-size: 14px;
      }
    }
  }
}

.new-portal .panel.billing {
  .panel-header {
    border-bottom: 1px solid $np-border-light;
  }

  .panel-body {
    padding-top: 25px;
  }

  .table .badge {
    margin-top: -4px;
    margin-bottom: -4px;
  }

  .table td {
    white-space: nowrap;
  }

  .table.panel-table tbody td.table-row-actions > a {
    opacity: 1;
  }

  .info-section {
    padding: 15px 25px;
    background-color: $np-bg-light;
    border: 1px solid $np-border-light;
    border-radius: 4px;
  }

  .key-value-list {
    & > li {
      margin: 20px 0;
      font-size: 14px;
    }

    .key {
      flex: 0 0 180px;
      text-align: right;
      padding-right: 20px;
    }

    .value div + div {
      margin-top: 5px;
      font-weight: 400;
      opacity: 0.8;
    }
  }
}

.new-portal .btn.btn-loading.btn-icon svg.btn-loading-icon {
  width: 20px;
  height: 20px;
}

.biometric-icon {
  display: flex;
  justify-content: center;
  margin: 0;

  svg {
    width: 150px;
    opacity: 0.7;
  }

  + .form-field {
    flex-direction: column;

    .btn + .btn {
      margin: 10px 0 0 0 !important;
    }
  }
}

.user-2fa-empty-state {
  text-align: center;
  padding: 20px 0 10px;

  svg {
    width: 120px;
    height: 120px;
    margin: 0 0 10px 0;
    opacity: 0.7;
  }

  p {
    opacity: 0.4;
  }
}

.two-fa-icon {
  opacity: 0.7;
}

.new-portal {
  .panel.service-rules .alert.alert-danger {
    h5 {
      margin-top: 10px;
    }

    box-shadow: none;
    border: none;
    background-color: rgba($np-danger, 0.1);
    align-items: flex-start;

    a {
      text-decoration: underline;

      &:hover {
        color: #000;
      }
    }
  }
}

.drop-area {
  width: 50px;
  border-top: 3px solid rgb(0, 60, 255);
}

// MONACO EDITOR READONLY MODE
.service-rules.code-editor.readonly .monaco-editor-background {
  background-color: #f7f7fa;
}

.service-rules.code-editor.readonly .monaco-editor .margin {
  background-color: #efeff5;
}

.service-rules.code-editor.readonly .monaco-editor .minimap-decorations-layer {
  background-color: #000056;
  opacity: 0.1;
}

// MONACO EDITOR EDITABLE MODE
.service-rules.code-editor:not(.readonly) .code-editor-wrapper {
  outline: 3px solid #a294bc;
}

@import '_redesign.scss';
@import '_leonel_additions.scss';

a {
  cursor: pointer;
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.9);
  }
}
